@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6fafd;
  background-image: url(./assets/img/home-bg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

@media screen and (max-width: 1000px) {
  body {
    background-size: contain; 
  }
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
